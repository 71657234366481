@import "./bootstrap/bootstrap.scss";
@import "./inter/inter.css";

html,
body {
  padding: 0;
  margin: 0;
  background-color: #eee;
}

* {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.text-right {
  text-align: right;
  float: right;
}
.down-indicator {
  padding-bottom: 10px;
}
@include media-breakpoint-up(sm) {
  .w-sm-50 {
    width: 50%;
  }
}

@include media-breakpoint-down(sm) {
  .w-sm-50 {
    width: 100% !important;
  }
}

.main.row {
  padding-top: 30vh;
  h1 {
    font-weight: 900;
  }
  h3 {
    font-weight: 300;
  }
}
.nav {
  ul {
    padding: 0;
    margin: 16px 0;
  }
  li {
    list-style-type: none;
    padding: 4px 15px;
    @include media-breakpoint-up(md) {
      padding: 2px 10px;
      display: inline;
    }
    a {
      color: #eee;
      font-size: 0.9em;
      &.active {
        font-weight: 700;
        border-bottom: 2px solid #eee;
      }
      &:hover,
      &:active {
        text-decoration: none;
        border-bottom: 2px solid #eee;
      }
    }
  }
}

footer {
  background-color: #eee;
  .nav a {
    color: #111;
    &.active {
      border-bottom: 2px solid #111;
    }
    &:hover,
    &:active {
      border-bottom: 2px solid #111;
    }
  }
}
